<template>
    <div class="custom-bg-secondary">  
      <div
        class="contactBG"
      >
        <b-container class="fromDiv mx-auto">
          <div class="text-white">
            <h2>{{ $t("Contact.ContactUs.ContactUs") }}</h2>
            <div class="line"></div>
            <h3 class="contactus-text">
              {{
                $t("Contact.ContactUs.HeaderQuestion") +
                $t("Contact.ContactUs.HeaderQuestion2")
              }}
            </h3>
          </div>
  
          <b-modal id="workWithPashText" hide-footer centered no-stacking size="lg">
            <b-card>
              <b-card-body>
                <div>
                  <h5>{{ $t("Contact.ContactUs.SendUsEmail") }}</h5>
                  <p class="mt-3">
                    <a
                      :href="'mailto:' + $t('Contact.ContactUs.SendUsEmailAddress')"
                      class="h5"
                      >{{ $t("Contact.ContactUs.SendUsEmailAddress") }}</a
                    >
                  </p>
                </div>
              </b-card-body>
            </b-card>
          </b-modal>
          <div
            class="forms-wrapper align-items-start"
          >
            <div class="form-column">
              <b-overlay :show="sending" rounded="lg">
                <b-card class="mb-6 mb-md-0">
                  <h5>{{ $t("Contact.FillOutForm") }}</h5>

                  <p>{{ $t("Contact.ConnectYouSoon") }}</p>
                  <b-form class="mt-4 pt-2" id="contactForm" @submit.prevent="handleForm()">
                    <b-form-group>
                      <label for="txtFirstName">{{
                        $t("contactInfo.label.firstName")
                      }}</label>
                      <b-form-input
                        type="text"
                        id="txtFirstName"
                        v-model="form.firstName"
                        :state="form.firstName ? true : null"
                      />
                    </b-form-group>
                    <b-form-group>
                      <label for="txtLastName">{{
                        $t("contactInfo.label.lastName")
                      }}</label>
                      <b-form-input
                        type="text"
                        id="txtLastName"
                        v-model="form.lastName"
                        :state="form.lastName ? true : null"
                      />
                    </b-form-group>
                    <b-form-group>
                      <label for="txtEmail">{{
                        $t("contactInfo.label.emailAddress")
                      }}</label>
                      <b-form-input
                        type="email"
                        id="txtEmail"
                        v-model="form.email"
                        :state="form.email ? emailFormat.test(form.email) : null"
                      />
                    </b-form-group>
                    <b-form-group>
                      <label for="ddlCountry">{{ $t("contactInfo.label.country") }}</label
                      ><b-form-select id="ddlCountry" v-model="form.country">
                        <b-form-select-option>{{
                          $t("contactInfo.label.selectCountry")
                        }}</b-form-select-option>
                        <b-form-select-option
                          v-for="country in $store.getters.countries"
                          :key="country.Name"
                          :value="country.Code"
                          >{{ country.Name }}</b-form-select-option
                        >
                      </b-form-select>
                      <div style="margin-top: -30px; width: 28px; float: right">
                        <b-icon
                          v-if="form.country"
                          icon="check"
                          variant="success"
                          scale="2"
                          class="mt-n3 mr-2"
                        />
                        <b-icon
                          v-else
                          icon="caret-down-fill"
                          variant="primary"
                          class="mt-n3 mr-2"
                        />
                      </div>
                    </b-form-group>
  
                    <b-form-group>
                      <label for="txtPhone"
                        >{{ $t("contactInfo.label.phoneNumber") }}
                      </label>
                      <!-- <b-form-input type="tel" id="txtPhone" v-model="form.phone" /> -->
  
                      <b-input-group class="row m-0">
                        <VuePhoneNumberInput
                          class="col p-0"
                          v-model="form.phone"
                          show-code-on-list
                          :translations="{
                            countrySelectorLabel: $t('Contact.countrySelectorLabel'),
                            countrySelectorError: $t('Contact.countrySelectorError'),
                            phoneNumberLabel: $t('Contact.phoneNumberLabel'),
                            example: $t('Contact.example'),
                          }"
                          :default-country-code="
                            $store.getters.country ? $store.getters.country.Code : 'CA'
                          "
                          @update="setMobilePayload($event)"
                        />
  
                        <!-- <b-input-group-append>
                            <div
                              class="right-rounded border border-left-0 px-3"
                              :class="validMobile ? 'border-success' : ''"
                            ></div>
                          </b-input-group-append> -->
                      </b-input-group>
                      <div style="margin-top: -31px; width: 28px; float: right">
                        <b-icon
                          v-if="validMobile"
                          icon="check"
                          variant="success"
                          scale="2"
                          class="mt-n3 mr-2"
                        ></b-icon>
                      </div>
                    </b-form-group>

                    <b-form-group>
                      <label for="ddlSubject">{{ $t("Contact.Subject") }}</label
                      ><b-form-select id="ddlSubject" v-model="form.subject">
                        <b-form-select-option disabled>
                          <!-- {{ $t("Contact.SelectSubject") }} -->
                        </b-form-select-option>
                        <b-form-select-option
                          v-for="subject in subjects"
                          :key="subject"
                          :value="subject"
                          >{{ $t(`Contact.${subject}`) }}</b-form-select-option
                        >
                      </b-form-select>
                      <div style="margin-top: -30px; width: 28px; float: right">
                        <b-icon
                          v-if="form.subject"
                          icon="check"
                          variant="success"
                          scale="2"
                          class="mt-n3 mr-2"
                        ></b-icon>
                        <b-icon
                          v-else
                          icon="caret-down-fill"
                          variant="primary"
                          class="mt-n3 mr-2"
                        ></b-icon>
                      </div>
                    </b-form-group>
  
                    <b-form-group>
                      <label for="txtMessage">Message</label>
                      <b-form-textarea
                        id="txtMessage"
                        v-model="form.message"
                      ></b-form-textarea>
                    </b-form-group>
  
                    <section
                      class="border border-danger mt-3 text-center p-0"
                      v-show="errorMessage"
                    >
                      <b class="text-danger">{{ errorMessage }}</b>
                    </section>
  
                    <div class="pt-6">
                      <b-btn type="submit" variant="primary" class="w-100">
                        {{ $t("global.button.submit") }}
                      </b-btn>
                    </div>
                  </b-form>
                </b-card>
              </b-overlay>
            </div>
            <div class="form-column">
              <div>
                <b-card class="mb-6 mb-md-4">
                  <h5 class="mb-4">
                    {{ $t("Contact.CustomerServiceCountry") }}
                  </h5>
                  <p class="mb-6">
                    {{ $t("Contact.ContactUs.SalesRepresentativesDescription") }}
                  </p>
                  <!-- <p class="mt-4">
                    <router-link :to="{name: 'ContactReps'}" class="btn btn-primary"
                      >Find your Sales Agent now</router-link
                    >
                  </p> -->
                  <div>
                    <b-btn
                      pill
                      variant="primary"
                      @click="openModal"
                      class="px-4 find-agent-btn"
                    >
                      {{ $t("Contact.ContactUs.FindSalesAgent") }}
                    </b-btn>
                  </div>
                </b-card>
                <b-card>
                  <h5 class="mb-4">{{ $t("Contact.ContactUs.SendUsEmail") }}</h5>
                  <a href="mailto:contact@pashmotors.com" target="_blank">
                    <img src="@/assets/img/icons/envelop.svg" alt="" />
                    contact@pashmotors.com
                  </a>
                </b-card>
              </div>
            </div>
          </div>
        </b-container>
        <b-modal
          hide-header
          hide-footer
          no-close-on-backdrop
          no-close-on-esc
          centered
          id="messageSendSuccess"
        >
          <template #default="{ hide }">
            <div>
              <b-button class="close px-2 pb-2" @click="hide()">x</b-button>
            </div>
            <div class="text-center m-3">
              <h3>{{ $t("Contact.MessageSuccess") }}</h3>
  
              <div class="mt-4">
                <b-link @click="$router.go(0)">
                  {{ $t("Contact.BackLinkText") }}
                </b-link>
              </div>
            </div>
          </template>
        </b-modal>
      </div>
      <Newsletter/>
    </div>
  </template>
  <script>
  import Vue from "vue";
  import VuePhoneNumberInput from "vue-phone-number-input";
  import {mapActions} from "vuex";
  import axios from "axios";
  import "vue-phone-number-input/dist/vue-phone-number-input.css";
  import { VueReCaptcha } from "vue-recaptcha-v3";
  Vue.use(VueReCaptcha, { siteKey: "6Lcb1qYiAAAAAAnFo885hqpkVQ3nRx8VyIFQpqhc" });
  import InfoIcon from "vue-material-design-icons/InformationOutline";
  import Newsletter from "@/components/global/Newsletter";
  import phoneMixin from "@/components/mixins/phoneMixin"
  
  export default {
    components: {
      InfoIcon,
      VuePhoneNumberInput,
      Newsletter,
      MENU: () => import("@/components/global/TheContactMenu.vue"),
    },
    mixins: [phoneMixin],
    props: {},
    data() {
      return {
        form: {},
        subjects: [
          "GeneralInquiry",
          "Comment",
          "WorkWithPashLB",
          "Partnerships",
          "WebsiteIssues",
          "Complaint",
          "BuyCars",
          "SellCars",
          "Other",
        ],
        emailFormat: /\S+@\S+\.\S+/,
        errorMessage: null,
        sharing: {
          url: "https://contact@pashmotors.com",
          title: "Say hi to Vite! A brand new, extremely fast development setup for Vue.",
          description: "Brand new development setup created by Evan You.",
          quote: "The hot reload is so fast it's near instant. - Evan You",
        },
        sending: false,
        text: null,
      };
    },
    methods: {
      ...mapActions(['setCustomerServiceModal']),
      openModal() {
        this.setCustomerServiceModal(true)
      },
      handleLink(text) {
        return text.replace(/ /g, '-')
      },
      async handleForm() {
        if (!this.form.firstName || !this.form.lastName)
          this.errorMessage = "please enter your name";
        else if (!this.form.email) this.errorMessage = this.$t("Validations.email.pleaseEnter");
        else if (!this.form.phone) this.errorMessage = this.$t("Validations.mobile.pleaseEnter");
        else if (!this.validMobile) this.errorMessage = this.$t("Validations.mobile.validNumber");
        else if (!this.form.subject) this.errorMessage = this.$t("Validations.form.selectSubject");
        else if (!this.form.message) this.errorMessage = this.$t("Validations.form.messageMissing");
        else {
          this.sending = true;
          let formData = new FormData();
          formData.append('Name', `${this.form.firstName} ${this.form.lastName}`)
          formData.append('Email', this.form.email)
          formData.append('CountryCode', this.form.country)
          formData.append('Mobile', this.mobilePayload.formattedNumber)
          formData.append('Subject', this.form.subject)
          formData.append('Message', this.form.message)
          formData.append('TypeId', 0)
          this.$recaptcha("login")
            .then(() => {
              axios
                .post("Basic/AddContactForm", formData, {
                  headers: {
                    'Content-Type': 'multipart/form-data;'
                  }
                })
                .then(() => {
                  this.sending = false;
                  this.$bvModal.show("messageSendSuccess");
                })
                .catch((error) => {
                  this.errorMessage = error.toString();
                  this.sending = false;
                })
                .finally(() => {
                  this.clearData();
                });
            })
            .catch(() => {
              this.$store.dispatch("showToast", {
                content: "Captcha Error, Please Try Again!",
                type: "error",
              });
            })
            .finally(() => (this.sending = false));
        }
      },
      clearData() {
        this.form = {};
        this.errorMessage = null;
        this.cv = null;
      },
    },
    mounted() {
      document.title = `${document.title} | ${this.type.replace("-", " ")}`;
    },
    beforeMount() {
      if (this.$store.getters.country) {
        this.form.country = this.$store.getters.country.Code;
      }
      this.$recaptchaInstance.showBadge();
    },
    beforeDestroy() {
      this.$recaptchaInstance.hideBadge();
    },
  };
  </script>
  
  <style scoped lang="scss">
  .contactBG {
    background-image: url("~@/assets/img/contactUs_bg.jpg");
    background-position: top;
    background-repeat: no-repeat;
    background-size: 100% 645px;
    @media (max-width: 991px) {
      background-size: 100% 365px;
    }
    @media (max-width: 575px) {
      background-size: 100% 330px;
    }
  }
  .freightBG,
  .inspectionBG,
  .representativesBG,
  .customersBG {
    background-position: top;
    background-repeat: no-repeat;
    background-size: 100% 930px;
    @media (max-width: 991px) {
      background-size: 100% 625px;
    }
    @media (max-width: 767px) {
      background-size: 100% 520px;
    }
    @media (max-width: 575px) {
      background-size: 150% 470px;
    }
    .fromDiv {
      @media (max-width: 767px) {
        padding-top: 300px;
      }
    }
  }
  .inspectionBG {
    background-image: url("~@/assets/img/inspectionAgent_bg.jpg");
    @media (max-width: 575px) {
      background-position: -140px 0px;
    }
  }
  .representativesBG {
    background-image: url("~@/assets/img/salesrep_bg.jpg");
    @media (max-width: 575px) {
      background-position: -140px 0px;
    }
  }
  .freightBG {
    background-image: url("~@/assets/img/freight.png");
  }
  .customersBG {
    background-image: url("~@/assets/img/customers.png");
  }
  .jobsBG {
    background-image: url("~@/assets/img/jobs.png");
    background-position: top;
    background-repeat: no-repeat;
    background-size: 100% 540px;
    @media (max-width: 991px) {
      background-size: 100% 450px;
    }
    @media (max-width: 767px) {
      background-size: 100% 465px;
    }
    @media (max-width: 575px) {
      background-size: 150% 290px;
    }
    .fromDiv {
      @media (max-width: 767px) {
        padding-top: 300px;
      }
      @media (max-width: 575px) {
        padding-top: 160px;
      }
    }
  }
  .fromDiv {
    padding-top: 160px;
    padding-bottom: 150px;
    margin-left: 105px;
    margin-right: 105px;
    @media (max-width: 1200px) {
      padding-right: 40px;
      padding-left: 40px;
    }
    @media (max-width: 991px) {
      padding-top: 82px;
    }
    @media (max-width: 575px) {
      padding-right: 16px;
      padding-left: 16px;
      padding-top: 100px;
    }
    h2 {
      @media (max-width: 991px) {
        font-weight: 600;
        font-size: 28px;
        line-height: 40px;
      }
      @media (max-width: 575px) {
        font-size: 24px;
      }
    }
  }
  .line {
    height: 6px;
    width: 163px;
    background: white;
    margin-bottom: 146px;
    position: relative;
    top: 5px;
    border-radius: 5px;
    @media (max-width: 991px) {
      margin-bottom: 55px;
      height: 4px;
      width: 115px;
    }
    @media (max-width: 575px) {
      top: -5px;
    }
  }
  .directory {
    margin-bottom: 0;
    border-radius: 0;
  }
  .title-style {
    margin-top: 131px;
    width: 50%;
    font-weight: 600;
    font-size: 34px;
    line-height: 48px;
    font-family: "Poppins", sans-serif !important;
  }
  
  @media (max-width: 991px) {
    .fromDiv {
      padding-bottom: 20px;
      margin-left: 16px;
      margin-right: 16px;
    }
    .line {
      margin-bottom: 50px;
    }
    .title-style {
      font-size: 28px;
      line-height: 40px;
      width: 60%;
    }
  }
  @media (max-width: 767px) {
    .title-style {
      margin-top: 0;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      font-family: "Inter", sans-serif !important;
    }
  }
  
  @media (max-width: 575px) {
    .line {
      height: 2px;
      width: 72px;
      margin-bottom: 35px;
    }
  
    .title-style {
      margin-top: 0;
      line-height: 28px;
      width: 100%;
    }
  }
  .dotted-line {
    border-bottom: 1px dotted;
  }
  .fromDiv {
    h3 {
      font-size: 34px !important;
      line-height: 48px !important;
      @media (max-width: 991px) {
        font-size: 24px !important;
        line-height: 32px !important;
      }
      @media (max-width: 575px) {
        font-size: 20px !important;
        line-height: 30px !important;
      }
    }
  }
  .forms-wrapper {
    margin-top: 40px;
    display: flex;
    column-gap: 24px;
    align-items: flex-end;
    @media (max-width: 991px) {
      column-gap: 16px;
      margin-top: 56px;
      align-items: center;
    }
    @media (max-width: 767px) {
      flex-direction: column;
      margin-top: 24px;
    }
    @media (max-width: 575px) {
      margin-top: 16px;
    }
  }
  .form-column {
    width: 50%;
    @media (max-width: 767px) {
      width: 100%;
    }
    @media (max-width: 991px) {
      h5 {
        font-size: 20px;
      }
    }
    @media (max-width: 575px) {
      h5 {
        font-size: 18px;
        line-height: 24px;
      }
    }
  
    .card-body {
      padding: 40px 64px;
      @media (max-width: 991px) {
        padding: 24px;
      }
      @media (max-width: 575px) {
        padding: 24px 16px;
      }
    }
    input,
    select {
      border-radius: 35px;
      height: 48px;
    }
    textarea {
      border-radius: 4px;
      height: 135px;
      resize: none;
    }
    .btn {
      border-radius: 40px;
      height: 64px;
      font-size: 20px;
      @media (max-width: 1200px) {
        height: 48px;
        font-size: 16px;
      }
    }
    .form-group {
      margin-bottom: 24px;
    }
    .find-agent-btn {
      min-width: 340px;
      display: inline-flex !important;
      @media (max-width: 1200px) {
        min-width: 100%;
      }
    }
  }
  .faq {
    margin-top: 64px;
    @media (max-width: 960px) {
      margin-top: 16px;
    }
    @media (max-width: 767px) {
      margin-top: 24px;
    }
    .card-body {
      padding: 40px 24px;
      @media (max-width: 575px) {
        padding: 16px;
      }
    }
    h5 {
      font-size: 20px;
      font-weight: 600;
      text-align: center;
      margin-bottom: 24px;
      @media (max-width: 575px) {
        font-size: 18px;
        margin-bottom: 16px;
      }
    }
    .faq-box {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      @media (max-width: 575px) {
        font-size: 14px;
      }
      &:first-child {
        border-top: 1px solid #dee2e6;
      }
      p {
        margin-bottom: 16px;
        width: 100%;
        padding: 0 16px;
        &.collapsed,
        &.not-collapsed {
          min-height: 56px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0;
        }
      }
      div {
        width: 100%;
      }
    }
  }
  .career {
    h4 {
      margin-bottom: 24px;
    }
    ul {
      padding: 0;
      list-style: none;
      margin: 0;
    }
    li {
      position: relative;
      padding-left: 16px;
      &:not(:last-child) {
        margin-bottom: 16px;
      }
      &:before {
        content: "";
        width: 8px;
        height: 8px;
        background: $light-blue;
        border-radius: 100%;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .attach-box {
    /* height: 100px; */
    border-radius: 4px;
    background: #e6f0f9;
    padding: 16px 24px;
    label {
      display: block;
      width: 100%;
    }
  }
  .upload-button {
    min-width: 100px;
    height: 32px;
    background: #d0d0d0;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    input {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
  }
  .contactus-text {
    width: 630px;
    @media (max-width: 767px) {
      width: 100%;
    }
    @media (max-width: 575px) {
      font-weight: 600;
      font-family: "Inter", sans-serif !important;
    }
  }
  </style>
  
  <style lang="scss">
  .form-column {
    .input-tel {
      input {
        border-radius: 35px !important;
        border: none !important;
        box-shadow: none !important;
        outline: none !important;
      }
    }
    .country-selector {
      border: none !important;
      height: 48px;
      position: relative;
      width: 100px;
      input {
        display: flex;
        align-items: center;
        padding: 0 0 0 48px !important;
        justify-content: center;
        display: flex;
        align-items: center;
      }
      &:after {
        content: "";
        position: absolute;
        width: 1px;
        height: 33px;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        background: #d0d0d0;
      }
    }
    .country-selector__input {
      height: 100%;
    }
    .vue-phone-number-input {
      border-radius: 35px !important;
      height: 48px !important;
      border: 1px solid #ccc !important;
      input {
        box-shadow: none !important;
        outline: none !important;
        border: none !important;
        background: none !important;
        height: 48px;
      }
    }
    .country-selector__label {
      display: none !important;
    }
    .country-selector__country-flag {
      top: 18px !important;
      left: 20px !important;
    }
    svg {
      display: none !important;
    }
  }
  .pash-breadcrumb {
    position: sticky;
    top: 109px;
    z-index: 99;
    background: #eff1f3 !important;
    @media (max-width: 991px) {
      top: 127px;
    }
  }
  </style>
  